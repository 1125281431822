.contact-section {
  background: linear-gradient(to bottom, #343a40, #f6f8fa);
  }
  .contact-section h2{
    color: white;
  }
  .contact-section h4{
    color: white;
  }
  .contact-section p{
    color: white;
  }
  .section-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
  }
  
  .map-container iframe {
    border: none;
    border-radius: 10px;
  }
  
  form .form-control {
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  form button {
    border-radius: 5px;
    padding: 10px 20px;
   
    
  }


.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}

.scroll-button {
    background: #00c6fb;
    color: white;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-size: 24px;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, background-color 0.2s;
    scroll-behavior: smooth;
}

.scroll-button:hover {
    background-color: #00bcd4;
    transform: scale(1.1);
}

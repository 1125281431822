.product-section {
  padding: 50px;
  background-color: #f9f9f9;
}

.web-service-introduction {
  text-align: center;
  margin-bottom: 40px;
}

.web-service-introduction h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.web-service-introduction p {
  font-size: 1.2rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 30px;
}

.service-images {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;  /* Added for responsiveness */
}

.service-images img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 15px;
}

.product-images {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;  /* Added for responsiveness */
}

.product-item {
  text-align: center;
  flex: 1 1 200px;  /* Allow items to grow and shrink, with a min width */
  margin-bottom: 20px;
}

.product-item img {
  width: 100%;
  max-width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
}

.product-item p {
  margin-top: 10px;
  font-size: 1.2rem;
  color: #333;
}
.wheyexpla{
 font-style: italic;
  color:#555;
  font-size: 1.2rem;
}


/* Media queries for responsiveness */
@media (max-width: 768px) {
  .web-service-introduction h2 {
    font-size: 2rem;
  }

  .web-service-introduction p {
    font-size: 1rem;
  }

  .service-images {
    flex-direction: column;  /* Stack images vertically on smaller screens */
  }

  .product-images {
    flex-direction: column;  /* Stack product items vertically */
    align-items: center;  /* Center align the products */
  }

  .product-item {
    flex: 1 1 80%;  /* Ensure each product item takes up most of the width */
    margin-bottom: 15px;
  }
}

@media (max-width: 480px) {
  .web-service-introduction h2 {
    font-size: 1.5rem;
  }

  .product-item img {
    max-width: 150px;
    height: 150px;  /* Adjust image size for smaller screens */
  }

  .product-item p {
    font-size: 1rem;
  }
}

/* Hero Section */
.hero {
  position: relative;
  text-align: center;
  color: #fff;
  padding: 100px 20px;
  background: url('../images/ladygym.jpg') center center/cover no-repeat;
  overflow: hidden;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Hero Heading */
.hero-heading {
  font-size: 3.5rem;
  font-weight: 800;
  margin-bottom: 20px;
  color: #ffffff;
  text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.6);
  animation: fadeIn 2s ease-in-out;
}

.hero-heading .highlight {
  color: #08f3f3;
  font-style: italic;
  text-shadow: 3px 3px 10px rgba(8, 243, 243, 0.7);
}

/* Hero Subheading */
.hero-subheading {
  font-size: 1.6rem;
  margin-bottom: 30px;
  font-weight: 400;
  line-height: 1.8;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.5);
  animation: fadeIn 2.5s ease-in-out;
}

/* Explore Button */
.explore-btn {
  position: relative;
  z-index: 2;
  padding: 15px 50px;
  background: linear-gradient(90deg, #08f3f3, #ffffff);
  color: #000;
  font-size: 1.4rem;
  font-weight: bold;
  border: none;
  border-radius: 30px;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  box-shadow: 0 5px 15px rgba(8, 243, 243, 0.4);
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  animation: fadeIn 3s ease-in-out;
}

.explore-btn:hover {
  background: linear-gradient(90deg, #0ea2dd, #e0faff);
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(8, 243, 243, 0.6);
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .hero-heading {
    font-size: 2.5rem;
  }

  .hero-subheading {
    font-size: 1.2rem;
  }

  .explore-btn {
    padding: 12px 40px;
    font-size: 1.2rem;
  }
}


/* Fireball Container Positioned at the Bottom */
.fireball-container {
  position: absolute;
  bottom: 5%; /* Position slightly above the bottom edge */
  left: 50%;
  transform: translateX(-50%);
  width: 300px;
  height: auto;
  display: flex;
  justify-content: center;
  gap: 15px; /* Spacing between fireballs */
  z-index: 2; /* Ensure visibility above the background */
}

/* Individual Fireballs */
.fireball {
  width: 60px;
  height: 60px;
  background: linear-gradient(90deg, #08f3f3, #eaece8);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 0.8rem;
  font-weight: bold;
  text-shadow: 0 0 10px rgba(235, 238, 240, 0.8);
  box-shadow: 0 0 20px rgba(26, 113, 194, 0.6);
  animation: riseAndFade 3s infinite ease-in-out;
}

/* Rise and Fade Animation */
@keyframes riseAndFade {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(-15px);
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .fireball-container {
    width: 250px;
    bottom: 10%;
  }

  .fireball {
    width: 50px;
    height: 50px;
    font-size: 0.6rem;
  }
}

.features {
  position: relative;
  text-align: center;
  padding: 5rem 2rem;
  background: linear-gradient(to bottom, #343a40, #f6f8fa);
  color: white;
  overflow: hidden;
}

.features-header h2 {
  font-size: 2.8rem;
  margin-bottom: 1rem;
  font-weight: bold;
  color: #ffffff;
}

.features-header p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: #d1e8ff;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2.5rem;
  z-index: 2;
}

.feature-card {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  z-index: 2;
}

.feature-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
}

.feature-icon {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: #00c6fb;
}

.feature-title {
  font-size: 1.6rem;
  margin-bottom: 0.8rem;
  font-weight: bold;
}

.feature-description {
  font-size: 1rem;
  line-height: 1.6;
  color: #00070e;
}

/* Bubble Animation */
.bubbles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}

.bubble {
  position: absolute;
  bottom: -150px;
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 50%;
  animation: bubble 20s infinite ease-in-out;
}

.bubble:nth-child(2) {
  width: 60px;
  height: 60px;
  left: 20%;
  animation-duration: 25s;
}

.bubble:nth-child(3) {
  width: 100px;
  height: 100px;
  left: 50%;
  animation-duration: 30s;
}
.bubble:nth-child(4) {
  width: 30px;
  height: 30px;
  left: 70%;
  animation-duration: 30s;
}
.bubble:nth-child(5) {
  width: 120px;
  height: 120px;
  left: 30%;
  animation-duration: 30s;
}
.bubble:nth-child(6) {
  width: 70px;
  height: 70px;
  left: 65%;
  animation-duration: 30s;
}

@keyframes bubble {
  0% {
    transform: translateY(0) translateX(0);
  }
  50% {
    transform: translateY(-300px) translateX(50px);
  }
  100% {
    transform: translateY(-600px) translateX(-50px);
  }
}

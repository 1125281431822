footer {
    background-color:#343a40;
    color: #fff;
    padding: 20px 0;
    text-align: center;
}

footer .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    
}


footer .footer-links {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;
}

footer .footer-links li {
    margin: 0;
}

footer .footer-links a {
    color: #fff;
    text-decoration: none;
    font-size: 14px;
}

footer .footer-links a:hover {
    text-decoration: underline;
    color:#00bcd4 ;
}

footer .social-icons {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 10px;
}

footer .social-icons a {
    color: #fff;
    font-size: 20px;
    text-decoration: none;
}

footer .social-icons a:hover {
    color: #00bcd4;
}

footer .copyright {
    font-size: 12px;
    margin-top: 20px;
}

/* Responsive Styling */
@media (max-width: 768px) {
    footer .container {
        flex-direction: column;
        text-align: center;
    }

    footer .social-icons {
        margin-top: 15px;
    }
}
